import '@babel/polyfill'
import 'mutationobserver-shim'
import Raphael from 'raphael/raphael'
global.Raphael = Raphael
import Vue from 'vue';
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import './plugins'
import App from './App.vue'
import router from './router'
import store from './store'
import './directives'
import VueHtmlToPaper from 'vue-html-to-paper'
import VueHtml2pdf from 'vue-html2pdf'
import Camera from 'easy-vue-camera';
import { BSpinner } from 'bootstrap-vue';
import VueSuggestion from 'vue-suggestion';
import VueClipboard from 'vue-clipboard2';
// import VueI18n from 'vue-i18n';

// Vue.use(VueI18n);
Vue.use(VueClipboard);
Vue.use(VueSuggestion);
Vue.use(Vuetify)
Vue.use(VueHtml2pdf);
Vue.use(Camera);
Vue.component('b-spinner', BSpinner);
const vuetify = new Vuetify({
  // Vuetify 옵션
})
const options = {
  autoClose: true,
}

Vue.use(VueHtmlToPaper, options);

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
