<template>
  <router-view v-if="show"></router-view>
</template>
<script>
// import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
// import 'remixicon/fonts/remixicon.css'

import axios from 'axios'

export default {
  name: 'App',
  data(){
    return {
      show:false,
    }
  },
  components: {
  },
  created() {
    this.show=false;
    axios.post('/api/loginstatus')
    .then( result => {
      // console.log(result.data)
      if (result.data.loginstatus == 'Login success') {

        console.log("user confirmed");
        // console.log(result.data)
        console.log(result.data.loginstatus);
        // console.log(result.data.dbs);
        this.$store.commit('userupdate',result.data.userinfo);
        this.$store.commit('dbupdate', result.data.dbs)
        // console.log("store");
        // console.log(this.$store.state.user);
        // console.log(this.$store.state.polist);
        // this.$router.push({name: 'layout.dashboard1'});

        this.show=true;
      } else {
        alert(result.data.loginstatus);
        this.$store.commit('userupdate',this.$store.state.userinit);
        this.$router.push({name: 'auth.login'});
        this.show=true;
      }
    }).catch((e)=>{
      console.log(e);
    })
  },
  methods: {
    // googleTranslateElementInit() {
    //   window.google.translate.TranslateElement(
    //     { pageLanguage: "en" },
    //     "google_translate_element"
    //   );
    // },
  }
}
</script>
<style lang="scss">
  @import "./assets/scss/backend.scss";
  @import "./assets/css/custom.css";
</style>
